<template>
  <div id="login">
      <br>
      <div style="text-align: center;">
         <input type="hidden" v-model="notUpwd">
      </div>
      <div class="group_input">
          <h1>管理员登录</h1>
          <br>
          <el-input v-model="uname" placeholder="用户名" style="width: 400px"></el-input>
          <br> <br>
          <el-input v-model="upwd" type="password" placeholder="密码" style="width: 400px"></el-input>
          <br> <br>
          <el-button type="primary" style="width: 400px" @click="login">登录</el-button>
      </div>


      <div style="text-align: center;margin-top: 400px;color: #fff;">
             Copyright  郑州存者信息技术有限公司 版权所有<br> <br>
      
             
      	   <div style="width:300px;margin:0 auto; padding:20px 0;">
                 <img src="../assets/img/beian.png" >
      	   		 		<a target="_blank"
							href="https://beian.miit.gov.cn/" 
					style="display:inline-block;text-decoration:none;height:20px;line-height:20px;color: #fff;">
					<img src="" style="float:left;"/>
						<p style="float:left;height:20px;line-height:20px;margin: 0px 0px 0px 5px; color:#fff;">
						豫ICP备2021019532号-3</p></a>
      	   </div>
      </div>
  </div>
</template>
<style>
  #login{
        background-image: url(../assets/img/timg1.jpg);
        background-size: cover;
        background-attachment: fixed;
        		width: 100%;
        		height: 100%;
        		position: fixed;
        		top: 0;
        		left: 0;
        		right: 0;
        		bottom: 0;
  }
    #login .group_input{
        width: 500px;
        height:250px;
        background: #fff;
        margin: 70px auto;
        border-radius: 4px;
        padding: 20px 40px;
        text-align: center;

    }
</style>
<script>

  export default {
    data(){
      return {
          uname:'',
          upwd:'',
          notUpwd:'0',
      }
    },
    mounted(){
        // document.title='登录'
    },
    methods:{
        login(){
            let _this=this

            if(!_this.uname){
               _this.$message.error('请输入用户名')
               return
            }
            if(!_this.upwd){
               _this.$message.error('请输入密码')
               return
            }
            var obj={
                uname:_this.uname,
                upwd:_this.upwd,
                notUpwd:_this.notUpwd
            }
			const loading = this.$loading({
			  text: '加载中',
			  background: 'rgba(255, 255, 255, 0.1)',
			  spinner: 'el-icon-loading',
			  customClass: 'loadingclass'
			})
          
            return
            this.axios.post(this.$domain+'/pc/index/login', obj).then(function (res) {
               
                    let result = res.data
                    if(result.code==200){
                         _this.$router.push({path:'/member/memberList'})
                        _this.$message.success(result.msg)
                         sessionStorage.setItem('token',result.data.token)
                       sessionStorage.setItem('cid',result.data.cid)
                        sessionStorage.setItem('shop_name',result.data.shop_name)
                        sessionStorage.setItem('shop_logo',result.data.shop_logo)
                    }else{
                        _this.upwd = ''
                        _this.$message.error(result.msg)
                    }
					 loading.close()
            })
        }
    },

  }
</script>
